import { TelegramLogo, TwitterLogo } from "assets";
import { Button } from "components/Atoms/Button/Button";
import { Link } from "react-router-dom";

export const ShareCode = ({
  dataUrl,
  BtnText,
  onClose,
  isTresureCongMsg,
  twitterShareMsg,
}: {
  dataUrl: string | undefined;
  BtnText: string;
  onClose: ()=>void;
  isTresureCongMsg?: boolean;
  twitterShareMsg?: string;
}) => {
  const textTwitter =
  twitterShareMsg ? twitterShareMsg : "I have staked my SuperNova Keys and waiting for my $RVV bag https://pic.twitter.com/Yct0ozulTY";
  const textTelegram =
    "I have staked my SuperNova Keys and waiting for my $RVV bag ";
  return (
    <div className="flex justify-center items-center py-3 px-4 gap-8 mobile:flex-col mobile:gap-6 ">
      {!isTresureCongMsg && <div className="group w-[155px] hover:drop-shadow-primary ">
        <Link
          to={`https://telegram.me/share/url?url=${dataUrl}&text=${textTelegram}`}
          target="_blank"
        >
          <Button
            // onClick={() => handleOpenRefferal("telegram")}
            isBorder={true}
            bgColor={true}
            logo={<TelegramLogo />}
            isBorderLabel="SHARE ON TELEGRAM"
            color="white"
            CLASSNAME=" text-text-primary group-hover:text-text-secondary"
          />
        </Link>
      </div>}

      <div className="group  hover:drop-shadow-primary ">
        <Link
          to={`https://twitter.com/intent/tweet?text=${textTwitter}&url=${dataUrl}`}
          target="_blank"
        >
          <Button
            // onClick={() => { handleOpenRefferal("twitter") }}
            isBorder={true}
            bgColor={true}
            logo={<TwitterLogo />}
            isBorderLabel="SHARE ON X"
            color="white"
            CLASSNAME=" text-text-primary group-hover:text-text-secondary pr-2 mobile:px-3"
          />
        </Link>
      </div>
      <div className="group  hover:drop-shadow-primary  ">
        <Button
          onClick={()=>{onClose()}}
          isBorder={true}
          bgColor={true}
          isBorderLabel={BtnText}
          color="white"
          CLASSNAME=" text-text-primary group-hover:text-text-secondary px-2 mobile:px-4"
        />
      </div>
    </div>
  );
};
