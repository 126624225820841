import { CancleIcon } from "assets";
import { Images } from "assets/Images";
import { Card } from "components/Atoms/Card/Card";
import { Typography } from "components/Atoms/Typography/Typography";
import { Modal } from "components/Molecules/Modal";
import { ShareCode } from "./ShareCode";
import { GIFS } from "assets/gifs";

interface CongratulationsProps {
  closeCongrats: () => void;
  textMsg?: string;
  twitterShareMsg?: string;
  telegramShareMsg?: string;
  isTresureCongMsg?: boolean;
  tresureChestType?: string | null;
}

export const Congratulations = ({
  closeCongrats,
  textMsg,
  isTresureCongMsg,
  tresureChestType,
  twitterShareMsg,
}: CongratulationsProps) => {
  return (
    <Modal blurImg>
      <Card pseudoElement="default" className="!px-0 !py-2 md:!h-auto bg-black">
        <button
          onClick={() => closeCongrats()}
          className="cursor-pointer absolute top-2 right-2 z-30"
        >
          <CancleIcon />
        </button>
        <div className="flex flex-col justify-center items-center relative overflow-hidden px-5">
          {isTresureCongMsg && (
            <div>
              <Typography
                isIcon={false}
                variant="h2"
                font="bold"
                className="text-text-primary uppercase cursor-default mobile:text-xxxl text-center"
                JustifyContentCenter={true}
              >
                {tresureChestType}
              </Typography>
              <div className="w-32 h-auto mx-auto">
                <img
                  src={
                    tresureChestType === "BRONZE"
                      ? GIFS.BRONZE
                      : tresureChestType === "SILVER"
                      ? GIFS.SILVER
                      : tresureChestType === "GOLD"
                      ? GIFS.GOLD
                      : tresureChestType === "EMERALD"
                      ? GIFS.EMERALD
                      : GIFS.CHEST_TREASURE
                  }
                  alt="treasurebox"
                  className="object-contain w-full h-auto"
                />
              </div>
            </div>
          )}

          <Typography
            isIcon={false}
            variant="h2"
            font="bold"
            className="text-text-primary uppercase cursor-default mobile:text-xxxl text-center"
          >
            {textMsg}
          </Typography>
          <div className="my-2 md:my-3 ">
            <img src={Images.LINE} alt="" />
          </div>
          <>
            <img
              src={Images.CONGRATS_GIF}
              alt="congrats"
              className="absolute h-full w-[50%] object-contain right-0 -rotate-12 "
            />
            <img
              src={Images.CONGRATS_GIF}
              alt="congrats"
              className="absolute h-full w-[50%] object-contain left-0 rotate-12"
            />
          </>
          <ShareCode
            BtnText="back to supernova"
            dataUrl=""
            onClose={closeCongrats}
            isTresureCongMsg={isTresureCongMsg}
            twitterShareMsg={twitterShareMsg}
          />
        </div>
      </Card>
    </Modal>
  );
};
