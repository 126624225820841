import { useConnectModal } from "@rainbow-me/rainbowkit";
import nftAbi from "Abi/NftAbi.json";
import StackAbi from "Abi/stakingManagerAbi.json";
import { Info, Share } from "assets";
import { GIFS } from "assets/gifs";
import { Images } from "assets/Images";
import { Button } from "components/Atoms/Button/Button";
import { Card } from "components/Atoms/Card/Card";
import Tooltip from "components/Atoms/Tooltip";
import { Typography } from "components/Atoms/Typography/Typography";
import CustomWalletModal from "components/ConnectWallet";
import { Loader } from "components/Loader";
import {
  ENV,
  POLYGON_CHAINID,
  SUPER_NOVA_NFT_CONTRACT_ADDRESS,
  SUPER_NOVA_STAKE_CONTRACT_ADDRESS,
  SUPER_NOVA_STAKE_DURATION,
} from "constants/config";
import { shortenAddress } from "constants/function";
import { isDevices } from "constants/utils";
import { useUser } from "context/userContext";
import { ethers } from "ethers";
import { useCustomError } from "hooks/accessTokenExpire";
import { useGetDashboardMatrics } from "hooks/usegetDashboardMatrics";
import { useGetUserDetails } from "hooks/usegetUserDetails";
import useStakeNftData from "hooks/useStakeNftData";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { GET_STAKING_MODULE, GET_TREASURE_CHEST } from "services/apiService";
import { Address } from "viem";
import {
  useAccount,
  useAccountEffect,
  useClient,
  useConnect,
  useDisconnect,
  useSwitchChain,
  useWriteContract,
} from "wagmi";
import { SuperNovaCard } from "./Card";
import { AddNFT } from "./popup/AddNFT";
import { Congratulations } from "./popup/Congratulations";
import RpcErrorModal from "./popup/RpcErrorModal";
import { ShareCodeModal } from "./popup/ShareCodeModal";
import ShowError from "./popup/ShowError";
import { SwitchChain } from "./popup/SwitchChain";
import { UnStakeNft } from "./popup/Unstake";
import { useLocation, useNavigate } from "react-router-dom";

export const SuperNova = () => {
  const { user, stakingData } = useUser();
  const [addNftModal, setAddNftModal] = useState<boolean>(false);
  const [isNftLoading, setIsNftLoading] = useState<boolean>(false);
  const { openConnectModal } = useConnectModal();
  const { connect, connectors } = useConnect();
  const client = useClient();
  const { handleError, isJsonSerializable } = useCustomError();
  const { address, chainId, isConnected, isDisconnected, connector } =
    useAccount();
  const { disconnectAsync } = useDisconnect();
  const { switchChainAsync } = useSwitchChain();
  const { writeContractAsync } = useWriteContract();
  const [nftTokenIds, setnftTokenIds] = useState<any[]>([]);
  const [isaddStakeLoading, setAddStakeLoading] = useState<boolean>(false);
  const [userStakeList, setUserStakeList] = useState<any>([]);
  const [invitesCode, setInviteCode] = useState<any>([]);
  const [btnClick, setBrnClick] = useState<boolean>(false);
  const [showCongrasts, setShowCongrasts] = useState<boolean>(false);
  const [showReferralModal, setshowReferralModal] = useState<boolean>(false);
  const [isUnStake, setIsUnStake] = useState<boolean>(false);
  const [storeCode, setStoreCode] = useState();
  const { getDashboardMarics } = useGetDashboardMatrics(false);
  const { getUserDetails } = useGetUserDetails(false);
  const [showCustomWalletModal, setShowCustomWalletModal] = useState(false);
  const [isShowError, setIsShowError] = useState<boolean>(false);
  const [stakeWalletAddress, setStakeWalletAddress] = useState<string>("");
  const [isRpcError, setIsRpcError] = useState<boolean>(false);
  const [isUnstakeButtonVisible, setIsUnstakeButtonVisible] =
    useState<boolean>(false);
  const [stakeApiData, setStakeApiData] = useState([]); //Invite-Code APi Stake Data
  const [isRewardRevealButton, setIsRewardRevealButton] =
    useState<boolean>(false);
  const [isStakeReward, setIsStakeReward] = useState<boolean>(false);
  const [tresureChestType, setTresureChestType] = useState<string | null>(null);
  const [isTresureCongMsg, setIsTresureCongMsg] = useState<boolean>(false);
  const chestRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const isWalletConnected = localStorage.getItem("walletConnect");

  const nftContractAdress = SUPER_NOVA_NFT_CONTRACT_ADDRESS;
  const stakContractAdress = SUPER_NOVA_STAKE_CONTRACT_ADDRESS;

  const handleAddNftModalToggle = () => {
    setAddNftModal(!addNftModal);
  };

  const handleCongratsAfterStake = (isRewardCongrats: boolean) => {
    setIsTresureCongMsg(isRewardCongrats);
    setShowCongrasts(!showCongrasts);
  };

  const handleisUnStakeToggle = () => {
    setIsUnStake(!isUnStake);
  };

  const handleRpcModalToggle = () => {
    setIsRpcError(!isRpcError);
  };

  // let {
  //   refetch: refetchGetStake,
  //   data: stakeNftdata,
  //   isLoading,
  // }: any = useReadContract({
  //   address: stakContractAdress,
  //   abi: StackAbi.abi,
  //   functionName: "getStakedNFTs",
  //   args: [address],
  // });
  const {
    data: stakeNftdata,
    isLoading,
    refetchGetStake,
  } = useStakeNftData(address || "");

  // console.log(stakeNftdata , "SkaingdataList ::::")

  const handleOpenModalMetaMask = () => {
    if (isDevices()) {
      setShowCustomWalletModal(true);
    } else {
      openConnectModal && openConnectModal();
    }
  };

  const getProvider = () => {
    if (!client) {
      return;
    }
    return new ethers.BrowserProvider(client);
  };

  const handleContract = (
    contractType: "NFT" | "STAKE",
    contract: "write" | "read"
  ) => {
    let Contract: any;
    const provider = getProvider();
    // const signer = getSigner()
    let Abi;
    if (contractType === "NFT") {
      Abi = nftAbi.abi;
      Contract = new ethers.Contract(nftContractAdress, Abi, provider);
    } else if (contractType === "STAKE") {
      const stackabi = StackAbi.abi;
      Contract = new ethers.Contract(stakContractAdress, stackabi, provider);
    }
    return Contract;
  };

  const listTokensOfOwner = async (token: any, account: any) => {
    try {
      const startBlock = 4615770;
      const endBlock = await token.runner.provider.getBlockNumber();
      const sentLogs = [];
      const receivedLogs = [];

      const blockNumberWindow = 5000;

      for (let i = startBlock; i < endBlock; i += blockNumberWindow) {
        const _startBlock = i;
        const _endBlock = Math.min(endBlock, i + (blockNumberWindow - 1));

        const sentFilter = token.filters.Transfer(account, null);
        sentFilter.fromBlock = _startBlock;
        sentFilter.toBlock = _endBlock;
        const _sentLogs = await token.queryFilter(sentFilter);
        sentLogs.push(..._sentLogs);

        const receiveFilter = token.filters.Transfer(null, account);
        receiveFilter.fromBlock = _startBlock;
        receiveFilter.toBlock = _endBlock;
        const _receivedLogs = await token.queryFilter(receiveFilter);
        receivedLogs.push(..._receivedLogs);
      }

      const logs = sentLogs
        .concat(receivedLogs)
        .sort(
          (a, b) =>
            a.blockNumber - b.blockNumber ||
            a.transactionIndex - b.TransactionIndex
        );

      const owned = new Set();

      for (const log of logs) {
        const { from, to, tokenId } = log.args;

        if (to.toLowerCase() === account.toLowerCase()) {
          owned.add(Number(tokenId));
        } else if (from.toLowerCase() === account.toLowerCase()) {
          owned.delete(Number(tokenId));
        }
      }
      return Array.from(owned).sort((a: any, b: any) => a - b);
    } catch (error: any) {
      handleError(error);
    }
  };

  // Get the balance of the wallet address for the given ERC721 contract
  const getTokenIds = async (token: any, account: any) => {
    try {
      const balance = await token.balanceOf(account);
      const tokenIds = [];
      //console.log(`Balance ${account}:`, balance);
      for (let i = 0; i < balance; i++) {
        const tokenId = await token.tokenOfOwnerByIndex(account, i);
        tokenIds.push(tokenId.toString());
      }
      // console.log(`Token IDs owned by ${account}:`, tokenIds);
      return tokenIds;
    } catch (error: any) {
      handleError(error);
      console.error("Error fetching token IDs:", error);
    }
  };

  const handleCheckAproval = async (address: string) => {
    const contract = handleContract("NFT", "read");
    const checkApprove = await contract?.isApprovedForAll(
      address,
      stakContractAdress
    );

    return checkApprove;
  };

  const getTxReceipt = async (txHash: string) => {
    const provider = getProvider();
    if (!provider) {
      return;
    }
    const txReceipt = await provider.waitForTransaction(txHash, 5);

    return txReceipt;
  };

  const handleSetApprovalForAll = async () => {
    const txHash = await writeContractAsync({
      abi: nftAbi.abi,
      address: nftContractAdress as Address,
      functionName: "setApprovalForAll",
      args: [stakContractAdress, true],
    });

    return await getTxReceipt(txHash);
  };

  const handleSetStake = async (userId: string, tokenIds: number[]) => {
    const txHash = await writeContractAsync({
      abi: StackAbi.abi,
      address: stakContractAdress as Address,
      functionName: "stake",
      args: [userId, tokenIds],
    });
    return await getTxReceipt(txHash);
  };

  const handleStacking = async (
    address: string,
    userId: string,
    nftSelectedTokenids: number[]
  ) => {
    let toastId;
    try {
      setAddStakeLoading(true);
      toastId = toast.loading("Staking in progress...");
      toast.warning('Check Your Wallet Extension to Switch Network or Transaction Related Options' , {autoClose : 5000})
      // await handlecheckChainId();
      // check approval
      const aproval = await handleCheckAproval(address);

      if (!aproval) {
        // set Aprrove for stake
        await handleSetApprovalForAll();
      }

      //set stake
      let cmTx = await handleSetStake(userId, nftSelectedTokenids);
      if (cmTx) {
        toast.success("Staking Successful");
        handleAddNftModalToggle();
        handleCongratsAfterStake(false);
        handleVerifiedStakeWallet(address);
        refetchGetStake();
        return true;
      }
    } catch (error: any) {
      if (isJsonSerializable(error)) {
        const err = JSON.parse(JSON.stringify(error));
        if (err.cause.code === -32603) {
          setIsRpcError(true);
          return;
        }
      }
      handleError(error);
    } finally {
      toast.dismiss(toastId);
      setAddStakeLoading(false);
    }
  };

  const handleGetNftTokenIds = async (address: string) => {
    try {
      setIsNftLoading(true);
      const NFTContract = handleContract("NFT", "read");
      let tokenIds;
      if (ENV === "DEV") {
        tokenIds = await listTokensOfOwner(NFTContract, address);
      } else {
        tokenIds = await getTokenIds(NFTContract, address);
      }
      if (tokenIds) {
        setnftTokenIds([...tokenIds]);
      }
    } catch (error: any) {
      handleError(error);
    } finally {
      setIsNftLoading(false);
    }
  };

  const handleSwitchNetwork = async () => {
    // const toadtId = toast.loading("Switching Network...");
    setAddStakeLoading(true);
    try {
      await switchChainAsync({ chainId: POLYGON_CHAINID });
    } catch (error: any) {
      if (isJsonSerializable(error)) {
        const customeErr = JSON.parse(JSON.stringify(error));
        if (customeErr?.code === 4001) {
          handleDisconnectWallet();
        }
      }
      handleError(error);
    } finally {
      // toast.dismiss(toadtId);
      setAddStakeLoading(false);
    }
  };

  const handleConnectStackWallet = async (data: any) => {
    try {
      const { address } = data;
      if (address) {
        toast.success("Wallet Connected");
        localStorage.setItem("walletConnect", "true");
      }
    } catch (error: any) {
      handleError(error);
    }
  };

  const handleDisconnectWallet = async () => {
    await disconnectAsync({ connector });
    setUserStakeList([]);
    setInviteCode([]);
    setIsShowError(false);
  };

  const handleVerifiedStakeWallet = async (connected_wallet: any) => {
    try {
      let res = await GET_STAKING_MODULE();
      const { InviteCodes, StakedNfts, StakedRewards } = res?.data;
      const currentDate = Date.now();

      //check stake wallet address
      if (
        StakedNfts &&
        StakedNfts.length > 0 &&
        StakedNfts[0]?.wallet_address === connected_wallet
      ) {
        //desc sort
        let descOrderStakeApiNfts = StakedNfts.sort(
          (a: any, b: any) => Number(b.stakedOn) - Number(a.stakedOn)
        );

        if (descOrderStakeApiNfts.length > 0) {
          //check first stake time. time is over or not and unstake button
          const endTime =
            descOrderStakeApiNfts[descOrderStakeApiNfts.length - 1]?.stakedOn *
              1000 +
            (stakingData?.bonus_multiplier || 0) * 1000;
          if (currentDate > endTime) {
            setIsUnstakeButtonVisible(true);
          } else {
            setIsUnstakeButtonVisible(false);
          }

          // check first value stake time and click to reveal button show
          const lastStakeTime =
            descOrderStakeApiNfts[0]?.stakedOn * 1000 +
            (stakingData?.bonus_multiplier || 0) * 1000;
          if (currentDate > lastStakeTime) {
            setIsRewardRevealButton(true);
          } else {
            setIsRewardRevealButton(false);
          }
        }

        //check chest reward
        if (StakedRewards && StakedRewards.length > 0) {
          setTresureChestType(StakedRewards[0].chest_type);
          setIsStakeReward(true);
        } else {
          setIsStakeReward(false);
          setTresureChestType(null);
        }

        setStakeApiData(descOrderStakeApiNfts);
        setInviteCode(InviteCodes);
        return true;
      } else if (StakedNfts && StakedNfts.length === 0) {
        return true;
      } else {
        setStakeWalletAddress(StakedNfts[0]?.wallet_address);
        return false;
      }
    } catch (error: any) {
      handleError(error);
    }
  };

  const handleConnectButtonClick = async (address: any) => {
    const toastId = toast.loading("connecting.....");
    try {
      setAddStakeLoading(true);
      let isWalletSameWallet: any = await handleVerifiedStakeWallet(address);
      if (isWalletSameWallet) {
        await handleConnectStackWallet({ address });
      } else {
        await disconnectAsync({ connector });
        setIsShowError(true);
      }
    } catch (error: any) {
      handleError(error);
    } finally {
      setAddStakeLoading(false);
      toast.dismiss(toastId);
    }
  };

  const handleIsTimeOver = () => {
    const StakeStartTime = Number(stakingData?.bonus_referral_start_time);
    const sTime = new Date(StakeStartTime);
    const StakeEndTime = new Date(
      Number(stakingData?.bonus_referral_end_time || 0) * 1000
    );

    const currentTime = new Date();

    if (currentTime < sTime) {
      //stake time not start
      toast.error("Staking Time Is Not Start");
      return null;
    } else if (currentTime > StakeEndTime) {
      //Stake time has ended
      toast.error("Staking Time Is Over");
      return true;
    } else {
      //Stake time is still ongoing.
      return false;
    }
  };

  const handleUnStakeNft = async (userId: string, tokenIds: number[]) => {
    let toastId = toast.loading("UnStaking...");
    toast.warning('Check Your Wallet Extension to Switch Network or Transaction Related Options' , {autoClose : 5000})
    try {
      setAddStakeLoading(true);
      const txHash = await writeContractAsync({
        abi: StackAbi.abi,
        address: stakContractAdress as Address,
        functionName: "unstake",
        args: [userId, tokenIds],
      });
      let res = await getTxReceipt(txHash);
      if (res) {
        await refetchGetStake();
        toast.success("UnStake Successful");
        handleisUnStakeToggle();
      }
    } catch (error: any) {
      handleError(error);
    } finally {
      toast.dismiss(toastId);
      setAddStakeLoading(false);
    }
  };

  const handleGetTreasure = async () => {
    try {
      let res = await GET_TREASURE_CHEST();
      handleCongratsAfterStake(true);
      await handleVerifiedStakeWallet(address);
      console.log(res, "handleGetTreasure");
    } catch (error: any) {
      handleError(error);
    }
  };

  useEffect(() => {
    if (!user || user === null) {
      getDashboardMarics();
      getUserDetails();
    }
  }, [user]);

  useEffect(() => {
    const checkwallet = async () => {
      if (!btnClick) {
        let isWalletSameWallet: any = await handleVerifiedStakeWallet(address);
        if (!isWalletSameWallet) {
          await disconnectAsync({ connector });
          setIsShowError(true);
          return;
        }
      }

      handleGetNftTokenIds(address || "");

      if (stakeNftdata) {
        setUserStakeList([...stakeNftdata[1]]);
      }
    };
    if (isConnected && chainId === POLYGON_CHAINID && isWalletConnected) {
      checkwallet();
    }
  }, [isConnected, stakeNftdata, address, chainId, isWalletConnected]);

  useEffect(() => {
    if (isConnected && chainId !== POLYGON_CHAINID) {
      handleSwitchNetwork();
    }
  }, [isConnected, chainId]);

  useEffect(() => {
    if (isConnected && !isDisconnected && !isWalletConnected && !btnClick) {
      let isWalletConnected = localStorage.getItem("walletConnect");
      !isWalletConnected && handleDisconnectWallet();
    } else if (!isConnected && isDisconnected) {
      localStorage.removeItem("walletConnect");
    }
  }, [isConnected, isDisconnected, isWalletConnected]);

  useAccountEffect({
    onConnect(data) {
      handleConnectButtonClick(data?.address);
    },
  });

  useEffect(() => {
    if (!isLoading && location.hash === "#chest" && chestRef.current) {
      chestRef.current.scrollIntoView({ behavior: "smooth" });
      navigate("/supernova", { replace: true });
    }
  }, [location, isLoading]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="  relative min-h-[90vh] pb-14 "> 
      <div className="min-h-wrapper min-w-screen h-full w-full absolute -z-10 top-0 left-0 bg-superNova bg-fixed bg-top bg-cover bg-no-repeat before:content-[''] before:w-full before:h-full before:absolute before:top-0 before-left-0 before:bg-black/50">
        <img
          className={`object-cover sm:object-fill w-full h-full opacity-50`}
          src={Images.LEADERBOARD}
          alt="leaderboard"
        />
      </div>
      <div className="AstroWrapper container max-w-[70%] mx-auto py-5 pb-10 mobile:max-w-[92%] ">
        <div className="flex flex-col md:flex-row gap-y-4 items-center justify-between">
          <Typography
            isIcon={false}
            variant="h2"
            className="text-text-primary cursor-default uppercase"
          >
            supernova key
          </Typography>
          {!isConnected ? (
            <Button
              size="medium"
              color="white"
              bgColor
              isBorder={true}
              isBorderLabel={"Connect wallet"}
              disable={(address && chainId === POLYGON_CHAINID) || isLoading}
              CLASSNAME={`text-text-primary group-hover:text-text-secondary px-3 `}
              className="mobile:!w-full"
              onClick={async () => {
                await disconnectAsync();
                setBrnClick(true);
                handleOpenModalMetaMask();
              }}
            />
          ) : (
            <div className="flex flex-row items-center gap-x-4">
              {userStakeList.length > 0 && isUnstakeButtonVisible && (
                <button
                  onClick={handleisUnStakeToggle}
                  // disabled={isUnstakeButtondisable}
                  type="button"
                  className="btn py-1 font-bold px-5 !text-sm disabled:cursor-not-allowed disabled:after:bg-[#181818] disabled:hover:text-text-primary"
                >
                  Unstake
                </button>
              )}
              <div className="text-text-primary !cursor-default flex flex-row gap-x-2 flex-wrap md:flex-col md:items-end mobile:w-full mobile:justify-between">
                <Typography isIcon={false}>
                  {shortenAddress(address || "", 5)}
                </Typography>
                <Typography
                  onClick={async () => {
                    if (!isLoading && !isaddStakeLoading) {
                      await disconnectAsync({ connector });
                      await handleDisconnectWallet();
                    }
                  }}
                  isIcon={false}
                  className={`text-text-secondary underline uppercase ${
                    !isLoading && !isaddStakeLoading
                      ? "!cursor-pointer "
                      : "!cursor-not-allowed "
                  }`}
                >
                  Disconnect
                </Typography>
              </div>
            </div>
          )}
        </div>

        <div
          className={`scrollcolorprimary grid h-[30rem] py-10 overflow-hidden overflow-y-auto justify-center items-center md:justify-items-center md:grid-cols-2 lg:grid-cols-3 gap-x-5 xl:grid-cols-4 my-10  ${
            userStakeList.length > 0 ? "gap-y-16" : "gap-y-10"
          }`}
        >
          {userStakeList
            .concat(new Array(50 - userStakeList.length).fill(null))
            .map((cardName: any, idx: number) => (
              <SuperNovaCard
                key={cardName + idx}
                data={cardName}
                stakeDuration={
                  Number(stakingData?.bonus_multiplier) ||
                  SUPER_NOVA_STAKE_DURATION
                }
                isLoading={isLoading}
                isUnstakeButtondisable={isaddStakeLoading}
                cardType={cardName === null ? "EMT" : "FLL"}
                onClickEMT={async () => {
                  if (!isaddStakeLoading && !isConnected && !address) {
                    toast.error("Connect your Wallet First");
                  } else if (!isaddStakeLoading && isConnected) {
                    if (!handleIsTimeOver() && handleIsTimeOver() !== null) {
                      handleAddNftModalToggle();
                    }
                  }
                }}
                // unStakeClick={() => {
                //   handleUnStakeNft(user?.id || "", [Number(cardName?.tokenId)]);
                // }}
              />
            ))}
        </div>
        <Typography
          isIcon={false}
          className="uppercase text-3xl text-text-primary md:tracking-widest"
        >
          supernova holder benefits
        </Typography>
        <Card
          pseudoElement="third"
          fullWidth={true}
          className="!w-full my-10 relative !min-h-[200px] p-5 !h-full bg-black"
          childClassName="w-full h-full !p-0 !overflow-visible"
        >
          {/* lock start --------*/}
          {(!isConnected ||
            (stakeNftdata &&
              stakeNftdata[1].length === 0 &&
              stakeApiData &&
              stakeApiData.length === 0)) && (
            <div className="absolute inset-0 flex items-center justify-center backdrop-blur-md w-full h-full group">
              <div>
                <div className="w-12 h-12">
                  <img
                    src={Images.lOCK}
                    alt="lock"
                    className="w-full h-full object-contain"
                  />
                </div>
                <Typography isIcon={false}></Typography>
              </div>
            </div>
          )}
          {/* lock end --------*/}

          <div className="text-text-primary w-full h-full flex flex-col md:flex-row items-center justify-between gap-x-5 lg:w-[85%] xl:w-[70%] mx-auto">
            <div className="flex items-center flex-col md:flex-row mobile:gap-y-3 md:gap-x-3">
              <div className="w-16 h-16">
                <img
                  src={Images.RVV_Shard}
                  alt="rvv"
                  className="w-full h-full object-contain"
                />
              </div>
              <div className="">
                <Typography
                  isIcon={false}
                  className="text-3xl uppercase mobile:text-center"
                >
                  $RVV PRE-SALE whitelist CODES
                </Typography>
                <Typography
                  isIcon={false}
                  className="text-lg uppercase text-text-lightgray mobile:text-center"
                >
                  Get access to the pre-sale and receive 3% cashback from the
                  amount your 3 friends buy.
                </Typography>
              </div>
            </div>
            <div className="space-y-3 md:w-[20%] lg:w-[25%] xl:w-[25%]">
              {invitesCode.map((item: any, idx: number) => (
                <Typography
                  key={item?.code || idx + "invitecode"}
                  // onClick={() => {
                  //   setSharereferralCode(item?.code || "")
                  // }}
                  onClick={() => {
                    if (item?.is_used) {
                      return;
                    }
                    setshowReferralModal(true);
                    setStoreCode(item?.code);
                  }}
                  isIcon={false}
                  pclassName={`${
                    item?.is_used ? "!cursor-not-allowed" : "!cursor-pointer"
                  }`}
                  className={`flex flex-row item-center gap-3 text-xl justify-between w-full ${
                    item?.is_used
                      ? "text-text-lightgray !cursor-not-allowed"
                      : "text-text-primary !cursor-pointer"
                  }`}
                >
                  {shortenAddress(item?.code || "", 4)}
                  <span
                    className={`${
                      item?.is_used
                        ? "!text-text-lightgray !cursor-not-allowed"
                        : "!text-text-secondary !cursor-pointer"
                    } w-min h-full flex items-center gap-x-2`}
                  >
                    {/* <CopyIcon /> */}
                    {isConnected && item?.is_used ? (
                      <Tooltip
                        tooltipText={`You received ${item?.tokens} $RVV from this invite`}
                        className="cursor-pointer"
                      >
                        <Info />
                      </Tooltip>
                    ) : (
                      <Share />
                    )}
                  </span>
                </Typography>
              ))}
            </div>
          </div>
        </Card>
        {/* Tresure chest */}
        <div ref={chestRef}>
        {!isStakeReward ? (
          <Card
            pseudoElement="third"
            fullWidth={true}
            className="!w-full !h-full !min-h-[200px] my-10 text-text-primary p-5 relative bg-black"
            childClassName="!h-full !w-full flex flex-col md:flex-row items-center justify-around gap-x-5 !overflow-hidden  !p-0"
          >
            {/* lock start --------*/}
            {(!isConnected ||
              (stakeNftdata &&
                stakeNftdata[1].length === 0 &&
                stakeApiData &&
                stakeApiData.length === 0)) && (
              <div className="absolute inset-0 flex items-center justify-center backdrop-blur-md w-full h-full group">
                <div>
                  <div className="w-12 h-12">
                    <img
                      src={Images.lOCK}
                      alt="lock"
                      className="w-full h-full object-contain"
                    />
                  </div>
                  <Typography isIcon={false}></Typography>
                </div>
              </div>
            )}
            {/* lock end --------*/}
            <div className="flex flex-col md:flex-row items-center justify-between w-full h-full gap-x-5 lg:w-[85%] xl:w-[70%] mx-auto mobile:gap-y-5">
              <div className="w-40 h-hull flex flex-col justify-center items-center">
                <div className="w-32 h-auto">
                  <img
                    src={GIFS.CHEST_TREASURE}
                    alt="treasurebox"
                    className="object-contain w-full h-auto"
                  />
                  {/* <video src={VIDEOS.TRESURY_BOX} autoPlay muted loop playsInline className='hidden md:block'/> */}
                </div>
                {isConnected && isRewardRevealButton && (
                  <Button
                    size="medium"
                    color="white"
                    bgColor
                    isBorder={true}
                    isBorderLabel={"Click to reveal"}
                    CLASSNAME={`text-text-primary group-hover:text-text-secondary px-3 `}
                    className="mobile:!w-full"
                    onClick={() => handleGetTreasure()}
                  />
                )}
              </div>
              <div className="flex flex-col items-center md:items-start justify-between">
                <Typography
                  isIcon={false}
                  className="!text-3xl mobile:text-center uppercase"
                >
                  YOUR TREASURE CHEST IS BEEN CREATED{" "}
                </Typography>
                <Typography
                  isIcon={false}
                  className="text-lg mobile:text-center capitalize text-text-lightgray"
                >
                  It Will Reveal After the staking period ends. $100,000 USD
                  worth $RVV up for grabs.
                </Typography>
              </div>
            </div>
          </Card>
        ) : (
          <Card
            pseudoElement="third"
            fullWidth={true}
            className="!w-full !h-full !min-h-[200px] my-10 text-text-primary p-5 relative bg-black"
            childClassName="!h-full !w-full flex flex-col md:flex-row items-center justify-around gap-x-5 !overflow-hidden  !p-0"
          >
            {/* lock start --------*/}
            {(!isConnected ||
              (stakeNftdata &&
                stakeNftdata[1].length === 0 &&
                stakeApiData &&
                stakeApiData.length === 0)) && (
              <div className="absolute inset-0 flex items-center justify-center backdrop-blur-md w-full h-full group">
                <div>
                  <div className="w-12 h-12">
                    <img
                      src={Images.lOCK}
                      alt="lock"
                      className="w-full h-full object-contain"
                    />
                  </div>
                  <Typography isIcon={false}></Typography>
                </div>
              </div>
            )}
            {/* lock end --------*/}
            <div className="flex flex-col md:flex-row items-center justify-start w-full h-full gap-x-5 lg:w-[85%] xl:w-[70%] mx-auto mobile:gap-y-5">
              <div className="w-40 h-hull flex flex-col justify-center items-center">
                <div className="w-32 h-auto">
                  <img
                    src={
                      tresureChestType === "BRONZE"
                        ? GIFS.BRONZE
                        : tresureChestType === "SILVER"
                        ? GIFS.SILVER
                        : tresureChestType === "GOLD"
                        ? GIFS.GOLD
                        : tresureChestType === "EMERALD"
                        ? GIFS.EMERALD
                        : GIFS.CHEST_TREASURE
                    }
                    alt="treasurebox"
                    className="object-contain w-full h-auto"
                  />
                </div>
              </div>
              <div className="flex flex-col items-center md:items-start justify-between">
                <Typography
                  isIcon={false}
                  className="!text-3xl mobile:text-center uppercase"
                >
                  YOUR TREASURE CHEST IS BEEN CREATED{" "}
                </Typography>
                <Typography
                  isIcon={false}
                  className="text-lg mobile:text-center text-text-lightgray"
                >
                  You've received a {tresureChestType} treasure chest.
                </Typography>
              </div>
            </div>
          </Card>
        )}
        </div>
        <Card
          pseudoElement="third"
          fullWidth={true}
          className="!w-full !h-full !min-h-[200px] my-10 text-text-primary p-5 relative bg-black"
          childClassName="!h-full !w-full flex flex-col md:flex-row items-center justify-start gap-x-5 !overflow-hidden  !p-0"
        >
          {/* lock start --------*/}
          {(!isConnected ||
            (stakeNftdata &&
              stakeNftdata[1].length === 0 &&
              stakeApiData &&
              stakeApiData.length === 0)) && (
            <div className="absolute inset-0 flex items-center justify-center backdrop-blur-md w-full h-full group">
              <div>
                <div className="w-12 h-12">
                  <img
                    src={Images.lOCK}
                    alt="lock"
                    className="w-full h-full object-contain"
                  />
                </div>
                <Typography isIcon={false}></Typography>
              </div>
            </div>
          )}
          {/* lock end --------*/}
          <div className="flex flex-col md:flex-row items-center gap-x-10 lg:w-[85%] xl:w-[70%] mx-auto">
            <Typography
              isIcon={false}
              className={`text-text-secondary  !text-7xl ${
                !isConnected || (stakeNftdata && stakeNftdata[1].length === 0)
                  ? ""
                  : "drop-shadow-primary"
              }`}
            >
              15%
            </Typography>

            <div className="flex flex-col items-center md:items-start justify-center">
              <Typography
                isIcon={false}
                className="text-3xl mobile:text-center uppercase"
              >
                more boost
              </Typography>
              <Typography
                isIcon={false}
                className="text-lg mobile:text-center capitalize text-text-lightgray"
              >
                A lifetime 15% addon boost is activated as long as the user
                holds the NFT.
              </Typography>
            </div>
          </div>
        </Card>
      </div>

      {addNftModal && (
        <AddNFT
          onClose={handleAddNftModalToggle}
          nftTokenIds={nftTokenIds}
          setnftTokenIds={setnftTokenIds}
          isloading={isNftLoading}
          isStakeLoading={isaddStakeLoading}
          // handleGetNftTokenIds={handleGetNftTokenIds}
          userStakeList={userStakeList}
          setUserStakeList={setUserStakeList}
          onAddNFT={handleStacking}
          totalNoOfStakes={(stakeNftdata && stakeNftdata[1].length) || 0}
          checkTime={handleIsTimeOver}
        />
      )}

      {isUnStake && (
        <UnStakeNft
          onClose={handleisUnStakeToggle}
          unStakelist={userStakeList.filter((snft: any) => {
            const currentMillisecond = Math.floor(Date.now());
            const starttime = Number(snft?.stakedOn || 0) * 1000;
            const extraTime = Number(stakingData?.bonus_multiplier || 0) * 1000;
            const endtime = starttime + extraTime;
            return currentMillisecond >= endtime && snft;
          })}
          onUnstakeNFT={handleUnStakeNft}
          isStakeLoading={isaddStakeLoading}
        />
      )}

      {showCongrasts && (
        <Congratulations
          closeCongrats={() => setShowCongrasts(false)}
          textMsg={
            isTresureCongMsg
              ? "Congratulations on winning a treasure chest"
              : "Congratulations! You have staked SuperNova key"
          }
          twitterShareMsg={
            isTresureCongMsg
              ? `I just won the ${tresureChestType} treasure chest, Click here to stake your SuperNova NFT and claim your treasure chest! https://Blackpass.astranova.world`
              : "I have staked my SuperNova Keys and waiting for my $RVV bag https://pic.twitter.com/Yct0ozulTY"
          }
          isTresureCongMsg={isTresureCongMsg}
          tresureChestType={tresureChestType}
        />
      )}

      {showReferralModal && (
        <ShareCodeModal
          storeCodes={storeCode}
          closeShareCode={() => setshowReferralModal(false)}
        />
      )}

      {showCustomWalletModal && (
        <CustomWalletModal
          setShowCustomWalletModal={setShowCustomWalletModal}
          connect={connect}
          connectors={connectors}
          setLoading={setAddStakeLoading}
          isImmutableWalletHide={true}
        />
      )}

      {isShowError && (
        <ShowError
          handleDisconnect={handleDisconnectWallet}
          walletAddress={stakeWalletAddress}
        />
      )}

      {isRpcError && <RpcErrorModal close={handleRpcModalToggle} />}

      {isConnected && chainId !== POLYGON_CHAINID && <SwitchChain />}
    </div>
  );
};
