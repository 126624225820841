import { Images } from "assets/Images";
import { Typography } from "components/Atoms/Typography/Typography";
import LazyImage from "components/LazyImage";
import { SuperNovaProgressTimer } from "../Timer";

export const SuperNovaCard = ({
  cardType = "FLL",
  isSelectionButton = false,
  isShadowActiveOnFll = true,
  onClickEMT,
  data,
  handleAddNft,
  handleUnStakeNft,
  selectNFT,
  isLoading,
  unStakeClick,
  isUnstakeButtondisable,
  stakeDuration,
}: {
  cardType?: "EMT" | "FLL";
  isSelectionButton?: boolean;
  isShadowActiveOnFll?: boolean;
  onClickEMT?: () => void;
  data?: any;
  handleAddNft?: (data: number) => void;
  handleUnStakeNft?: (data: any) => void;
  selectNFT?: number[];
  isLoading?: boolean;
  unStakeClick?: () => void;
  isUnstakeButtondisable?: boolean;
  stakeDuration?: number;
}) => {
  const timestampMilliSeconds: any = Number(data?.stakedOn || 0) * 1000;

  //stakeDuration seconds add to  starttimes
  const endmultiplierMilliseconds = (stakeDuration || 0) * 1000;
  const newTimestampInMilliseconds =
    timestampMilliSeconds + endmultiplierMilliseconds;

  return (
    <div
      className={`${
        isShadowActiveOnFll
          ? "w-52 min-h-72 h-full"
          : "w-52 h-72 mobile:w-48 mobile:h-60"
      } mx-auto`}
    >
      {/* empty card */}
      {cardType === "EMT" ? (
        <div
          onClick={onClickEMT}
          className={`w-full h-full relative  transition-all duration-500  ${
            !isLoading && !isUnstakeButtondisable
              ? "hover:scale-105 hover:-translate-y-2 hover:drop-shadow-primary cursor-pointer"
              : "cursor-not-allowed"
          }`}
        >
          <img
            src={Images.EMPTY_CARD}
            alt="Add Stake"
            className="object-contain w-full h-full"
          />
          {!isLoading && (
            <div className="absolute w-full h-full inset-0 text-text-primary flex items-center justify-center">
              <div className="w-min h-min flex flex-col items-center justify-center">
                <span className="text-3xl">+</span>
                <Typography
                  isIcon={false}
                  font="bold"
                  className="tracking-widest"
                >
                  ADD
                </Typography>
              </div>
            </div>
          )}
        </div>
      ) : (
        //    fill card
        <div
          onClick={() => {
            if (!isLoading) {
              handleAddNft && handleAddNft(data);
              handleUnStakeNft && handleUnStakeNft(data);
            }
          }}
          className={`w-full h-full flex flex-col items-center gap-y-2 group ${
            isShadowActiveOnFll ? "cursor-default" : "cursor-pointer"
          }`}
        >
          <div
            className={`w-full h-full relative ${
              isLoading
                ? ""
                : isShadowActiveOnFll
                ? "drop-shadow-primary"
                : `${
                    !isLoading && selectNFT && selectNFT?.includes(data)
                      ? "drop-shadow-active"
                      : "drop-shadow-notActive"
                  } `
            }`}
          >
            <div className="w-[50%] h-[40%] m-auto translate-y-8 bg-black absolute inset-0 text-text-primary z-10 flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-all duration-500 px-2 bg-blend-multiply">
              <div>Token Id</div>
              <div className="break-all">
                #{Number(data?.tokenId || data || 0)}
              </div>
            </div>
            <LazyImage
              lowQualitySrc={Images.EMPTY_CARD_LOW}
              highQualitySrc={Images.FILL_CARD}
              alt="Stake"
              className="w-full h-full"
              imgClassName="object-contain w-full h-full"
            />

            {/* animation for select or unselect */}
            {!isLoading && isSelectionButton && (
              <div
                className={`w-full h-10 bg-transparent absolute top-[4.7rem] mobile:top-[3.3rem] left-0 before:content-[''] before:w-5 before:h-7  before:absolute before:top-0 before:bottom-0 before:my-auto before:left-5 before:rounded-full  after:w-5 after:h-7  after:absolute after:top-0 after:bottom-0 after:right-5 after:my-auto after:rounded-full ${
                  selectNFT && selectNFT?.includes(data)
                    ? "before:bg-green-700/30 after:bg-green-700/30 before:drop-shadow-active after:drop-shadow-active"
                    : "before:bg-red-500/30 after:bg-red-500/30 before:drop-shadow-notActive after:drop-shadow-notActive"
                }`}
              ></div>
            )}

            {/* buttons */}
            {!isLoading && isSelectionButton && (
              <div
                className="absolute w-12 -bottom-2 left-0 right-0 mx-auto cursor-pointer transition-colors
            duration-500 hover:drop-shadow-primary active:scale-75"
              >
                <img
                  src={
                    selectNFT && selectNFT?.includes(data)
                      ? Images.SELECTNFT
                      : Images.ADDNFT
                  }
                  alt=""
                  className="object-contain w-full h-auto"
                />
              </div>
            )}
          </div>
          {
            isShadowActiveOnFll && (            
              <SuperNovaProgressTimer
                key={data?.stakedOn + "timer"}
                startTime={timestampMilliSeconds}
                endTime={newTimestampInMilliseconds}
                component="SuperNova"                
              />
            )   
          }
        </div>
      )}
    </div>
  );
};
